<template>
  <div>
    <h1>Nội quy hỏi đáp</h1>
    <ck-content
        :content="content"
        @getData="updateContent"
        v-model="content"
    ></ck-content>
    <v-row class="align-center justify-center ma-0 pt-5">
      <v-btn color="primary" @click="save"
      >Lưu thông tin
      </v-btn
      >
    </v-row>
  </div>
</template>
<script>
import { GET_CONTACT,SAVE_CONTACT } from "@/store/contacts.module";
import {mapGetters} from "vuex";
import {SET_BREADCRUMB} from "../../store/breadcrumbs.module";
export default {
  data(){
    return{
      content : ''
    }
  },
  components:{
    CkContent :()=>import("@/components/ck-content")
  },
  async created() {
    await this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Nội quy hỏi đáp"},
    ]);
    await this.$store.dispatch(GET_CONTACT,{
      key : 'rule'
    })
    if (this.contact && this.contact.content){
      this.content = this.contact.content
    }
  },
  computed: {
    ...mapGetters({
      contact: "contact",
    }),
  },
  watch:{
    contact (newVal){
      if (newVal && newVal.content){
        this.content = newVal.content
      }
    }
  },
  methods:{
    updateContent(val){
      this.content = val
    },
    async  save(){
      await this.$store.dispatch(SAVE_CONTACT,{
        content : this.content,
        key : 'rule'
      })
      this.$toasted.success('Lưu thông tin thành công!!',{
        position :'top-right',
        duration : 3000
      })
    }
  }
}
</script>